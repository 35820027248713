import { Component, Input } from '@angular/core';

import { SiteData } from '../../shared/models/site';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent {
  @Input() site!: SiteData;

  constructor() {}

}
