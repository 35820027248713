import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';

import { BannerService } from '../shared/components/banner/banner.service';
import { SiteService } from '../shared/services/site.service';

@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.css']
})
export class SiteComponent implements OnInit {
  siteLoaded = false;
  constructor(
    private route: ActivatedRoute,
    private siteService: SiteService,
    private bannerService: BannerService) { }

  ngOnInit(): void {
    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => this.siteService.getSite(params.get('uuid')!))
      )
      .subscribe(
        () => this.siteLoaded = true,
        err => {
          console.error('error fetching site', err);
          this.bannerService.open('Error fetching site, check console.', ['Close']);
        });
  }

}
