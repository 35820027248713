<div *ngIf="!!site">
    <mat-list role="list" dense>
        <mat-list-item role="listitem">BUSINESSID: {{ info.BUSINESSID }}</mat-list-item>
        <mat-list-item role="listitem">CATEGORIES:
            <div *ngFor="let category of info.CATEGORIES">{{ category.title }}</div>
        </mat-list-item>
        <mat-list-item role="listitem">DESCRIPTION: {{ info.DESCRIPTION }}</mat-list-item>
        <mat-list-item style="height: 208px;">
            <h3 matLine>Hours of Operation: ({{ site.site.timezone }})</h3>
            <div matLine *ngFor="let hours of info.HOURS">
                <table>
                    <tbody>
                        <tr *ngFor="let hour of hours.open">
                            <td>{{ DAYS[hour.day] }}</td>
                            <td>{{ utilsService.formatYelpTime(hour.start) }}</td>
                            <td>to</td>
                            <td>{{ utilsService.formatYelpTime(hour.end) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </mat-list-item>
        <mat-list-item style="height: 160px;">
            <h3 matLine>Photos</h3>
            <div matLine class="d-flex flex-row flex-wrap">
                <div *ngFor="let photo of info.PHOTOS"><img class="px-2" [src]="photo" width="auto" height="128px"></div>
            </div>
        </mat-list-item>
        <mat-list-item role="listitem">PRICE: {{ info.PRICE }}</mat-list-item>
        <mat-list-item role="listitem">RATING: {{ info.RATING }}</mat-list-item>
        <mat-list-item role="listitem">REVIEWCOUNT: {{ info.REVIEWCOUNT }}</mat-list-item>
        <mat-list-item role="listitem">URL: <span class="url">{{ info.URL }}</span></mat-list-item>
    </mat-list>
</div>