import { Component, Input } from '@angular/core';

import { SiteData } from '../../shared/models/site';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent {
  @Input() site!: SiteData;

  constructor() { }

}
