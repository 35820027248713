<mat-toolbar color="accent">
    <span>Copernicus</span>
    <span class="spacer"></span>
    <button mat-icon-button aria-label="Home button" routerLink="home">
        <mat-icon>home</mat-icon>
    </button>
    <button mat-icon-button aria-label="Search button" routerLink="search">
        <mat-icon>search</mat-icon>
    </button>
    <app-google-button></app-google-button>
</mat-toolbar>
