import { Injectable } from '@angular/core';
import * as Validators from './edit-offer-details.validators.json';

@Injectable({
  providedIn: 'root'
})

export class UtilsEditOfferDetails {
  constructor() {}

  between(x: number, min: number, max: number) {
    return x >= min && x <= max;
  }

  validateOfferCategoryDefaultValues(offerEditDetailsForm:any):string[]{
      let between = this.between;
      let errors = [];
      let offerCategory = offerEditDetailsForm.site.site.offerCategory;
      let validators: any = (Validators  as  any).default;

      let offerValidations = validators[offerCategory];
      // console.log(validations, validators.);
      for (let validatorType in offerValidations) {
          for (let validatorParam in offerValidations[validatorType]) {
              let validateData  = offerEditDetailsForm.editForm.value[validatorType];
              if (offerValidations[validatorType].hasOwnProperty(validatorParam)) {
                let message = '';
                let message_part = '';
                let param = validateData[validatorParam];
                let min = offerValidations[validatorType][validatorParam][0];
                //if range has just one element we use same element for min and max.
                let max =  offerValidations[validatorType][validatorParam].length > 1 ? offerValidations[validatorType][validatorParam][1] : min;
                if (!between(param, min, max) && param !== undefined){
                  if (min == max) {
                    message_part = 'equal to ' +  min;
                  } else {
                    message_part = 'between ' + min + ' and ' + max;
                  }
                  message = message + validatorParam + ' should be '+ message_part;
                  errors.push(message);
                }
              }
          }
      }
      return errors;
  }
}
