import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { SiteService } from '../../shared/services/site.service';
import { SiteData } from '../../shared/models/site';

@Component({
  selector: 'app-edit-details',
  templateUrl: './edit-details.component.html',
  styleUrls: ['./edit-details.component.css']
})
export class EditDetailsComponent implements OnInit {
  site!: SiteData;
  detailsForm = this.fb.group({
    name: [null, Validators.required],
    displayName: null,
    corporation: null,
    territory: null,
    processorType: null,
  });
  filteredTerritories = combineLatest([
    this.detailsForm.get('territory')?.valueChanges,
    this.siteService.territories.pipe(map(list => list.map((e: { name: string; }) => e.name)))
  ]).pipe(
    map(([value, options]: any) => this._filterTerritories(value, options))
  );

  constructor(
    private fb: FormBuilder,
    private siteService: SiteService) {}

  ngOnInit() {
      this.site = this.siteService.currentSite;
      this.detailsForm.patchValue({
        name: this.site.site.name,
        displayName: this.site.site.additionalProperties.displayName,
        corporation: this.site.site.additionalProperties.corporation,
        territory: this.site.site.additionalProperties.territory,
        processorType: this.site.site.additionalProperties.processorType,
        visibility: this.site.site.visibility,
      });
      this.detailsForm.markAsPristine();
  }
  
  private _filterTerritories(value: string, options: string[]): string[] {
    const filterValue = value.toLowerCase();
    return options.filter(option => option.toLowerCase().includes(filterValue));
  }

  onSubmit(): void {
    if (this.detailsForm.pristine) {
      console.log('onSubmit', 'no changes to details form, skipping');
      return;
    }
    // TODO make sure submit button disabled until form valid
    const details = {} as any;
    // NB: control names are tightly coupled with API expected names
    Object.entries(this.detailsForm.controls).forEach(([key, control]) => {
      if (control.dirty) {
        details[key] = control.value;
      }
    });
    // already checked if form is pristine
    this.siteService.updateSiteSettings(this.site.site.uuid, details).subscribe();
  }
}
