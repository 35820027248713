<div *ngIf="!!site">
    <mat-list role="list" dense>
        <mat-list-item role="listitem">Processor Type: {{ site.site.additionalProperties.processorType }}</mat-list-item>
        <mat-list-item role="listitem">Corporation: {{ site.site.additionalProperties.corporation }}</mat-list-item>
        <mat-list-item role="listitem">Site Identifier: {{ site.site.additionalProperties.siteIdentifier }}</mat-list-item>
        <mat-list-item *ngIf="site.site.offerCategory === 'GAS'" role="listitem">Gas Brand: {{ site.site.additionalProperties.gasBrand }}</mat-list-item>
        <mat-list-item *ngIf="site.site.offerCategory === 'GAS'" role="listitem">OPIS Rack Brand: {{ site.site.additionalProperties.opisRackBrand }}</mat-list-item>
        <mat-list-item *ngIf="site.site.offerCategory === 'GAS'" role="listitem">OPIS Rack Terminal: {{ site.site.additionalProperties.opisRackTerminal }}</mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item role="listitem">Site UUID: {{ site.site.uuid }}</mat-list-item>
        <mat-list-item role="listitem">Name: {{ site.site.name }}</mat-list-item>
        <mat-list-item role="listitem">Merchant UUID: {{ site.site.merchantUuid }}</mat-list-item>
        <mat-list-item role="listitem">Offer Category: {{ site.site.offerCategory }}</mat-list-item>
        <mat-list-item role="listitem">Redemption Methods: {{ site.site.redemptionMethods }}</mat-list-item>
        <mat-list-item role="listitem">Display Name: {{ site.site.additionalProperties.displayName }}</mat-list-item>
        <mat-list-item role="listitem">DMA: {{ site.site.additionalProperties.territory }}</mat-list-item>
        <mat-list-item role="listitem">Visibility: {{ site.site.visibility }}</mat-list-item>
    </mat-list>
</div>