<div class="mat-elevation-z8">
  <span>Found {{ resultsLength }} sites (first 15 shown).</span>

  <table mat-table class="full-width-table" matSort aria-label="Elements">
    <ng-container matColumnDef="siteUuid">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
      <td mat-cell *matCellDef="let row">{{row.siteUuid}}</td>
    </ng-container>

    <ng-container matColumnDef="siteName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let row">{{row.siteName}}</td>
    </ng-container>

    <ng-container matColumnDef="corporation">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Corporation</th>
      <td mat-cell *matCellDef="let row">{{row.corporation}}</td>
    </ng-container>

    <ng-container matColumnDef="merchantUuid">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Merchant UUID</th>
      <td mat-cell *matCellDef="let row">{{row.merchantUuid}}</td>
    </ng-container>

    <ng-container matColumnDef="merchantName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Merchant Name</th>
      <td mat-cell *matCellDef="let row">{{row.merchantName}}</td>
    </ng-container>

    <ng-container matColumnDef="accountId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Account Id</th>
      <td mat-cell *matCellDef="let row">{{row.accountId}}</td>
    </ng-container>

    <ng-container matColumnDef="visibility">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Visibility</th>
      <td mat-cell *matCellDef="let row">{{row.visibility}}</td>
    </ng-container>

    <ng-container matColumnDef="offerCategory">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Offer Category</th>
      <td mat-cell *matCellDef="let row">{{row.offerCategory}}</td>
    </ng-container>

    <ng-container matColumnDef="territory">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Territory</th>
      <td mat-cell *matCellDef="let row">{{row.territory}}</td>
    </ng-container>

    <ng-container matColumnDef="address1">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
      <td mat-cell *matCellDef="let row">{{row.address1}}</td>
    </ng-container>

    <ng-container matColumnDef="locality">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>City</th>
      <td mat-cell *matCellDef="let row">{{row.locality}}</td>
    </ng-container>

    <ng-container matColumnDef="region">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
      <td mat-cell *matCellDef="let row">{{row.region}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="navigateTo(row.siteUuid)"></tr>
  </table>

</div>
