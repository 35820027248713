<ng-container *ngIf="!!site">
<a mat-button [routerLink]="['/site', site.site.uuid]">Back to Dashboard</a>
<form [formGroup]="detailsForm" novalidate (ngSubmit)="onSubmit()">
  <mat-card class="details-card">
    <mat-card-header>
      <mat-card-title>Edit Site Details ({{ site?.site?.name }})</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput placeholder="Name" formControlName="name">
            <mat-error *ngIf="detailsForm.controls['name'].hasError('required')">
              Name is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput placeholder="Display Name" formControlName="displayName">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput placeholder="Corporation" formControlName="corporation">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput placeholder="DMA" formControlName="territory" [matAutocomplete]="autoTerritory">
            <mat-autocomplete #autoTerritory="matAutocomplete">
              <mat-option *ngFor="let option of filteredTerritories | async" [value]="option">
                {{option}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput placeholder="Processor Type" formControlName="processorType">
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" type="submit">Submit</button>
    </mat-card-actions>
  </mat-card>
</form>
</ng-container>
