<ng-container *ngIf="!!site">
  <a mat-button [routerLink]="['/site', site.site.uuid]">Back to Dashboard</a>
  <form [formGroup]="editForm" novalidate (ngSubmit)="onSubmit()">
    <mat-card class="edit-card">
      <mat-card-header>
        <mat-card-title>Edit Offer Settings</mat-card-title>
      </mat-card-header>
      <mat-card-content class="container">
        <div formGroupName="defaultOfferSettings" class="item">
          <h3>Default Offer Settings</h3>
          <div class="row">
            <div class="col">
              <mat-form-field class="full-width">
                <input matInput placeholder="Distribution Mean" formControlName="distributionMean">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field class="full-width">
                <input matInput placeholder="Distribution Std Dev" formControlName="distributionStdDev">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field class="full-width">
                <input matInput placeholder="ROI" formControlName="roi">
                <mat-error>
                  ROI is <strong>{{ getErrorReason('roi') }}</strong>
                </mat-error>
            </mat-form-field>
            </div>
          </div>
          <ng-container *ngIf="isGasSite()">
            <div class="row">
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Minimum Offer" formControlName="minimumOffer">
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Maximum Offer" formControlName="maximumOffer">
                </mat-form-field>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!isGasSite()">
            <div class="row">
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Minimum Threshold" formControlName="minimumThreshold">
                  <mat-error>
                    Minimum Threshold is <strong>{{ getErrorReason('minimumThreshold') }}</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Maximum Cashback" formControlName="maximumCashback">
                  <mat-error>
                    Maximum Cashback is <strong>{{ getErrorReason('maximumCashback') }}</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Margin Percent" formControlName="marginPercent">
                  <mat-error>
                    Margin Percent is <strong>{{ getErrorReason('marginPercent') }}</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Minimum Percent Of Margin" formControlName="minimumPercentOfMargin">
                  <mat-error>
                    Minimum Percent Of Margin is <strong>{{ getErrorReason('minimumPercentOfMargin') }}</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Maximum Percent Of Margin" formControlName="maximumPercentOfMargin">
                  <mat-error>
                    Maximum Percent Of Margin is <strong>{{ getErrorReason('maximumPercentOfMargin') }}</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Fee Percent" formControlName="feePercent">
                  <mat-error>
                    Fee Percent is <strong>{{ getErrorReason('feePercent') }}</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </ng-container>
        </div>
        <div formGroupName="offerLimitSettings" class="item">
          <h3>Offer Limit Settings</h3>
          <div class="row">
            <div class="col">
              <mat-checkbox formControlName="enabled">Enabled</mat-checkbox> 
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field class="full-width">
                <input matInput placeholder="Limit" formControlName="limit">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field class="full-width">
                <mat-select placeholder="Period" formControlName="period">
                  <mat-option *ngFor="let p of OFFER_LIMIT_PERIODS" [value]="p">
                    {{ p | titlecase }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div formGroupName="marginSettings" *ngIf="isGasSite()" class="item">
          <h3>Margin Settings</h3>
          <div class="row">
            <div class="col">
              <mat-form-field class="full-width">
                <input matInput placeholder="Outside Gas Fee" formControlName="outsideGasFee">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field class="full-width">
                <input matInput placeholder="Incremental Fee" formControlName="incrementalFee">
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="primary" type="submit">Submit</button>
      </mat-card-actions>
    </mat-card>
  </form>
</ng-container>
