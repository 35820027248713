import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Auth } from 'aws-amplify';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public isAuthenticated = new ReplaySubject<boolean>(1);
  public hasWriteAccess = new ReplaySubject<boolean>(1);

  constructor(
      @Inject(DOCUMENT) private _document: Document,
      private router: Router) {
    this.hasWriteAccess.next(false);
    // crazyness follows:  next lines work in a constructor because the authentication sequence
    // uses a series of redirects to get back to this page.
    Auth.currentAuthenticatedUser().then(
      user => {
        this.isAuthenticated.next(true);
        const groups = user.signInUserSession.accessToken.payload['cognito:groups'] as string[];
        if (groups.includes('MerchantOps')) {
          this.hasWriteAccess.next(true);
        }
      },
      err => { // throws exception when not logged in - it will be fine
        this.isAuthenticated.next(false);
      }
    );
  }

  public signIn() {
    // Google API not required: page redirected to Google
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google}).then(creds => {
      this.isAuthenticated.next(true);
    });
  }

  public signOut() {
    this.isAuthenticated.next(false);
    return Auth.signOut().then(() => {
      this.router.navigate(['home']);  // not entirely required, but faster navigation
      this._document.defaultView?.location.reload();
    });
  }

}
