import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/services/auth.guard';
import { WriteGuard } from './shared/services/write.guard';
import { HomeComponent } from './home/home.component';
import { SearchComponent } from './search/search.component';
import { DashboardComponent } from './site/dashboard/dashboard.component';
import { EditAddressComponent } from './site/edit-address/edit-address.component';
import { EditDetailsComponent } from './site/edit-details/edit-details.component';
import { SiteComponent } from './site/site.component';
import { EditOfferDetailsComponent } from './site/edit-offer-details/edit-offer-details.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'search', component: SearchComponent, canActivate: [AuthGuard] },
  {
    path: 'site/:uuid',
    component: SiteComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: DashboardComponent },
      { path: 'edit-details', component: EditDetailsComponent, canActivate: [WriteGuard] },
      { path: 'edit-address', component: EditAddressComponent, canActivate: [WriteGuard] },
      { path: 'edit-offer-details', component: EditOfferDetailsComponent, canActivate: [WriteGuard] },
    ]
  },
  { path: '**', component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
