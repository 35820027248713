import { Component } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { SiteService } from '../shared/services/site.service';

const COLUMN_NAMES = [
  { name: 'siteName', label: 'Site Name' },
  { name: 'corporation', label: 'Corporation' },
  { name: 'merchantUuid', label: 'Merchant UUID' },
  { name: 'merchantName', label: 'Merchant Name' },
  { name: 'visibility', label: 'visibility' },
  { name: 'offerCategory', label: 'Offer Category' },
  { name: 'territory', label: 'Territory' },
  { name: 'address1', label: 'Address' },
  { name: 'locality', label: 'City' },
  { name: 'region', label: 'State' },
  { name: 'accountId', label: 'Account Id' }
];

const COMPARISON_TYPES = [
  { name: 'is', label: 'is' },
  { name: 'startsWith', label: 'starts with' },
  { name: 'contains', label: 'contains' },
  { name: 'endsWith', label: 'ends with' },
];

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent {
  searchForm = this.fb.group({
    siteUuid: null,
  });
  dynamicQueryForm = this.fb.group({
    criteria: this.fb.array([])
  });
  isAlpha = environment.tier === 'alpha';

  constructor(
    private fb: FormBuilder,
    public siteService: SiteService,
    private router: Router) { }

  onSubmit(): void {
    if (this.searchForm.get('siteUuid')!.value) {
      this.router.navigate(['/site', this.searchForm.get('siteUuid')!.value]);
    }
  }

  public get criteria(): FormArray {
    return this.dynamicQueryForm.get('criteria') as FormArray;
  }

  public addCriteriaInput() {
    this.criteria.push(this.fb.group({
      columnName: null,
      searchString: null,
      comparison: null
    }));
  }

  public getColumnNames(index: number): {name: string, label: string}[] {
    // TODO exclude names already used
    return COLUMN_NAMES;
  }

  public getComparisonTypes(): {name: string, label: string}[] {
    return COMPARISON_TYPES;
  }

  public onQuerySubmit() {
    console.log('onQuerySubmit', this.criteria.value);
    this.siteService.searchSites(this.criteria.value)
    .subscribe(data => {
      console.log('found sites', data);
    });
  }

  // for testing in alpha
  public get alphaSites(): any {
    return this.isAlpha
      ? { total: (environment as any).alphaSites.length, data: (environment as any).alphaSites }
      : null;
  }
}
