import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import Amplify, { Auth } from 'aws-amplify';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const updatedAwsConfig = {
  Auth: {
    identityPoolId:'us-east-1:a75bed0b-23d1-4bd4-8c1f-a0bdd77b5b93',
    region: 'us-east-1',
    identityPoolRegion: 'us-east-1',
    userPoolId: 'us-east-1_muyMAMXd2',
    userPoolWebClientId: '4o7icu9vg6fboqua98bgecco16',
    mandatorySignIn: false,
    oauth: {
      domain: 'copernicus.auth.us-east-1.amazoncognito.com',
      scope: [
          'aws.cognito.signin.user.admin',
          'email',
          'openid',
          'phone',
          'profile'
      ],
      redirectSignIn: environment.redirectUrl,
      redirectSignOut: environment.redirectUrl,
      responseType: 'code'
    }
  },
  API: {
    endpoints: [
      {
          name: "CopernicusAPI",
          endpoint: environment.apiEndpoint,
          custom_header: async () => { 
              return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
          }
      }
    ]
  }
}

Amplify.configure(updatedAwsConfig);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
