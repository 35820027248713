import { Injectable } from '@angular/core';

import { SiteData } from '../models/site';

const NOT_ALPHA_NUMERIC_SPACE = /[^a-z0-9\s]+/g;
const MULTIPLE_SPACES = /\s{2,}/g;
const ANY_SPACE = / /g;

const PARSED_PROVIDER_FIELDS = new Map<string, string[]>([
  ['YELP', ['CATEGORIES','HOURS','PHOTOS']],
]);

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  public normalizeSiteName(name: string): string {
    return name.toLowerCase().replace(NOT_ALPHA_NUMERIC_SPACE, '').replace(MULTIPLE_SPACES, ' ').replace(ANY_SPACE, '-');
  }

  public getHeaderImage(site: SiteData): string {
    let name = site.site.additionalProperties.displayName ? site.site.additionalProperties.displayName : site.site.name;
    return `https://static.upside-services.com/assets/app/hubview/headers/${site.site.merchantUuid}/${site.site.offerCategory.toUpperCase()}/${this.normalizeSiteName(name)}.png`;
  }

  isGasSite(site: SiteData): boolean {
    return site.site.offerCategory === 'GAS';
  }

  public getLogoImage(site: SiteData): string {
    let name = '';
    const { gasBrand, displayName } = site.site.additionalProperties;
    if (this.isGasSite(site) && gasBrand) {
      name = gasBrand;
    } else if (displayName){
      name = displayName;
    } else {
      name = site.site.name
    }
    let normalizedSiteName = this.normalizeSiteName(name);
    return `https://static.upside-services.com/assets/app/brands/${site.site.offerCategory.toLowerCase()}/${normalizedSiteName}.png`;
  }

  public mapProviderInfo(site: SiteData, provider: string): any {
    if (!site) {
      return {};
    }
    const rawInfo = site.information.siteProviderInformation.find((e: { providerName: string; }) => e.providerName === provider);
    if (!!rawInfo) {
      const parsedFields = PARSED_PROVIDER_FIELDS.get(provider) || [];
      return rawInfo.providerInformation
        .map((e: { dataType: string; dataValue: any; }) => ({dataType: e.dataType, dataValue: (parsedFields.includes(e.dataType) ? JSON.parse(e.dataValue) : e.dataValue)}))
        .reduce((o: any, e: { dataType: string; dataValue: any; }) => ({ ...o, [e.dataType]: e.dataValue}), {});
    }
    return {};
  }

  public formatYelpTime(time: string): string {
    // only deals with HHMM 24-hour format
    if (time.length != 4) {
      return time;  // keep sanity
    }
    if (time === '0000') {
      return 'Midnight';
    }
    if (time === '1200') {
      return 'Noon';
    }
    let hour = +time.substring(0, 2);
    let ampm = hour > 11 ? 'PM' : 'AM';
    if (hour > 12) {
      hour -= 12;
    }
    return `${hour}:${time.substring(2, 4)} ${ampm}`;
  }

}
