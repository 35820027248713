<div *ngIf="!!site" class="d-flex flex-row">
    <mat-list class="flex-grow-1">
        <mat-list-item *ngIf="site.site.additionalProperties.displayName">Display Name: {{ site.site.additionalProperties.displayName }}</mat-list-item>
        <mat-list-item *ngIf="logoPresent" style="height: 200px;">
            <h3 matLine>Logo</h3>
            <img matLine width="140px" height="140px" [src]="utilsService.getLogoImage(site)" (error)="logoPresent = false">
        </mat-list-item>
        <mat-list-item *ngIf="yelpInfo.RATING">Rating: {{ yelpInfo.RATING }}</mat-list-item>
        <mat-list-item *ngIf="yelpInfo.HOURS" style="height: 208px;">
            <h3 matLine>Hours of Operation: ({{ site.site.timezone }})</h3>
            <div matLine *ngFor="let hours of yelpInfo.HOURS">
                <table>
                    <tbody>
                        <tr *ngFor="let hour of hours.open">
                            <td>{{ DAYS[hour.day] }}</td>
                            <td>{{ utilsService.formatYelpTime(hour.start) }}</td>
                            <td>to</td>
                            <td>{{ utilsService.formatYelpTime(hour.end) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </mat-list-item>
    </mat-list>
    <mat-list class="flex-grow-2">
        <mat-list-item *ngIf="yelpInfo.PHOTOS" style="height: 140px;">
            <h3 matLine>Photos</h3>
            <div matLine class="d-flex flex-row flex-wrap">
                <div *ngFor="let photo of yelpInfo.PHOTOS"><img class="px-2" [src]="photo" width="auto" height="128px"></div>
            </div>
        </mat-list-item>
        <mat-list-item *ngIf="headerPresent" style="height: 200px;">
            <h3 matLine>Header</h3>
            <img matLine class="header" [src]="utilsService.getHeaderImage(site)" (error)="headerPresent = false">
        </mat-list-item>
        <mat-list-item style="height: 350px;">
            <h3 matLine>Street View</h3>
            <iframe matLine width="100%" height="300px" frameBorder="0" [src]="getStreetView(site)"></iframe>
        </mat-list-item>
    </mat-list>
</div>
