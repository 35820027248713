<div *ngIf="!!site">
    <mat-list>
        <mat-list-item>Vertical: {{ site.site.offerCategory | titlecase }}</mat-list-item>
        <ng-container [ngSwitch]="site.site.offerCategory">
        <ng-container *ngSwitchCase="'GAS'">
        <mat-list-item>ROI: {{ defaultOffer.roi?.percent | percent }}</mat-list-item>
        <mat-list-item>Incremental Fee: {{ marginSettings.incrementalFee?.percent | percent }}</mat-list-item>
        <mat-list-item>Outside Gas Fee: {{ marginSettings.outsideGasFee?.amount | currency }}</mat-list-item>
        <mat-list-item>Distribution Mean: {{ defaultOffer.distributionMean?.percent | percent }}</mat-list-item>
        <mat-list-item>Distribution Standard Deviation: {{ defaultOffer.distributionStdDev?.percent | percent }}</mat-list-item>
        <mat-list-item>Minimum Threshold: {{ defaultOffer.minimumThreshold?.amount | currency }}</mat-list-item>
        <mat-list-item>Maximum Cashback: {{ defaultOffer.maximumCashback?.amount | currency }}</mat-list-item>
        </ng-container>
        <ng-container *ngSwitchDefault>
        <mat-list-item>Margin: {{ defaultOffer.marginPercent | percent }}</mat-list-item>
        <mat-list-item>Min Percent of Margin: {{ defaultOffer.minimumPercentOfMargin | percent }}</mat-list-item>
        <mat-list-item>Max Percent of Margin: {{ defaultOffer.maximumPercentOfMargin | percent }}</mat-list-item>
        <mat-list-item>Fee Percent: {{ defaultOffer.feePercent | percent }}</mat-list-item>
        </ng-container>
        </ng-container>
        <mat-list-item>
            <span matLine>Offer Limit Settings</span>
            <span matLine>Enabled: {{ offerLimitSettings.enabled }}</span>
            <span matLine>Limit: {{ offerLimitSettings.limit }}</span>
            <span matLine>Period: {{ offerLimitSettings.period }}</span>
            <span matLine>Remaining Offers: {{offerLimitSettings.numOffersRemaining }}</span>
        </mat-list-item>
    </mat-list>
</div>
