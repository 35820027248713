export const environment = {
  production: false,
  googleApiKey: 'AIzaSyBjOlY7KSl-ZIWE9qJJNqYDE4NVN7x4xdA',
  tier: 'alpha',
  alphaSites: [
    { siteName: 'Valero', offerCategory: 'GAS', siteUuid: '7eb10161-de76-4b83-8c15-97313e8cb67f', merchantUuid: '65eeeef9-3f19-4ccc-8933-8ede9ca36d14', accountId: 'fbf51fd8-225e-4b9a-9005-a5ffa92adaca' },
    { siteName: 'Shell', siteUuid: 'f6d52a02-b159-40b0-8cca-e84a32e9ea06', merchantUuid: '65eeeef9-3f19-4ccc-8933-8ede9ca36d14', offerCategory: 'GAS', accountId: '0013600001VknhwAAB' },
    { siteName: 'BP', siteUuid: '84c8a2b3-e99c-49ea-aec3-71992e93c611', merchantUuid: '65eeeef9-3f19-4ccc-8933-8ede9ca36d14', offerCategory: 'GAS' },
    { siteName: 'Shell Food Mart', siteUuid: '1653b3d7-07ff-4fac-9392-7db6c37ff368', merchantUuid: '65eeeef9-3f19-4ccc-8933-8ede9ca36d14', offerCategory: 'GAS', accountId: 'account1345' },
  ],
  redirectUrl: 'https://copernicus.alpha.getupside.com/',
  apiEndpoint: 'https://8wf2435did.execute-api.us-east-1.amazonaws.com/alpha'
};
