import { Injectable } from '@angular/core';
import { RestAPI } from '@aws-amplify/api-rest';
import { from, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';

import { SiteData } from '../models/site';
import { SiteAddress } from '../models/site-address';
import { SiteListItem } from '../models/site-list-item';

const COPERNICUS_API_NAME = 'CopernicusAPI';
@Injectable({
  providedIn: 'root'
})
export class SiteService {
  public currentSite: SiteData = null as any;
  public siteSearchResults = new ReplaySubject<any>(1);
  public territories =
    from(RestAPI.get(COPERNICUS_API_NAME, '/api/v1/lookup/territory', null))
    .pipe(
      catchError(err => this.handleError(err)),
      shareReplay(1));

  constructor() { }

  public getNextSiteIdForCategory(offerCategory: string): Observable<string> {
    // one day this will call a lambda to query SQS
    return of('e393fc9b-1339-4486-85e2-4f9d5e74f15d')
  }

  public getSite(siteUuid: string): Observable<SiteData> {
    if (!!siteUuid) {
      this.currentSite = null as any;
      return from(RestAPI.get(COPERNICUS_API_NAME, `/api/v1/sites/${siteUuid}`, null))
        .pipe(
          tap(response => this.currentSite = response)
        );
    }
    return of(null) as any;
  }

  private handleError(err: any): Observable<any> {
    console.error(err);
    return of({});
  }

  public searchSites(criteria: { columnName: string, comparison: string, searchString: string }[]): Observable<any> {
    const myInit = {
      queryStringParameters: criteria
        .reduce((o: any, e) => ({...o, [e.columnName]: `${e.comparison}:${e.searchString}`}),
          {fetchCount: 15})
    };
    return from(RestAPI.get(COPERNICUS_API_NAME, '/api/v1/sites', myInit))
      .pipe(
        map(response => ({
          total: response.total,
          data: this.mapResponseData(response.data)
        })),
        tap(data => this.siteSearchResults.next(data)));
  }

  private mapResponseData(data: any[]): SiteListItem[] {
    if (data.length < 2) {
      return [];
    }
    // response data is an array of arrays with the first row as headers
    const headers = data.shift();
    return data.map(row => (row
      .map((e: any, i: string | number) => ([headers[i], e]))
      .reduce((o: any, e: any[]) => ({...o, [e[0]]: e[1]}), {})));
  }

  public updateSiteAddress(siteUuid: string, address: SiteAddress, forceGeocode: boolean = true): Observable<SiteAddress> {
    const myInit = {
      queryStringParameters: { forceGeocode },
      body: address
    };
    return from(RestAPI.put(COPERNICUS_API_NAME, `/api/v1/sites/${siteUuid}/address`, myInit));
  }

  public updateSiteSettings(siteUuid: string, settings: any): Observable<any> {
    const myInit = {
      body: settings
    };
    return from(RestAPI.put(COPERNICUS_API_NAME, `/api/v1/sites/${siteUuid}/settings`, myInit));
  }

  public validateSiteSettings(siteUuid: string, settings: any): Observable<any> {
    const myInit = {
      body: settings
    };
    return from(RestAPI.post(COPERNICUS_API_NAME, `/api/v1/sites/${siteUuid}/settings/validate`, myInit));
  }

}

