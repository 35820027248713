<form [formGroup]="searchForm" novalidate (ngSubmit)="onSubmit()">
    <mat-card class="site-card">
        <mat-card-header>
            <mat-card-title>Search by Site ID</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="row">
                <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Site UUID" formControlName="siteUuid">
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button color="primary" type="submit">Search</button>
        </mat-card-actions>
    </mat-card>
</form>

<form [formGroup]="dynamicQueryForm" novalidate (ngSubmit)="onQuerySubmit()">
    <mat-card class="site-card">
        <mat-card-header>
            <mat-card-title matTooltip="This search uses the data warehouse, so it can be very slow." matTooltipPosition="right">Search by Site Properties</mat-card-title>
        </mat-card-header>
        <mat-card-content formArrayName="criteria">
            <div class="row" *ngFor="let criterium of criteria.controls; let i=index">
                <div class="col" [formGroupName]="i">
                    <mat-form-field>
                        <mat-label>Column Name</mat-label>
                        <mat-select formControlName="columnName">
                            <mat-option *ngFor="let column of getColumnNames(i)" [value]="column.name">{{ column.label }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Comparison</mat-label>
                        <mat-select formControlName="comparison">
                            <mat-option *ngFor="let comp of getComparisonTypes()" [value]="comp.name">{{ comp.label }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Search String</mat-label>
                        <input matInput formControlName="searchString">
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <button mat-button type="button" (click)="addCriteriaInput()">Add Criteria</button>
            <button mat-raised-button color="primary" type="submit">Search</button>
        </mat-card-actions>
    </mat-card>
</form>

<mat-card *ngIf="siteService.siteSearchResults | async as siteSearchResults">
    <mat-card-content>
        <app-site-list [siteSearchResults]="siteSearchResults"></app-site-list>
    </mat-card-content>
</mat-card>

<mat-card *ngIf="isAlpha" class="test-card">
    <mat-card-header>
        <mat-card-title>Test sites for Alpha</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-site-list [siteSearchResults]="alphaSites"></app-site-list>
    </mat-card-content>
</mat-card>
