import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

import { SiteData } from '../../shared/models/site';
import { UtilsService } from '../../shared/services/utils.service';

@Component({
  selector: 'app-display-details',
  templateUrl: './display-details.component.html',
  styleUrls: ['./display-details.component.css']
})
export class DisplayDetailsComponent implements OnChanges {
  @Input() site!: SiteData;
  DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  logoPresent = true;
  headerPresent = true;

  constructor(
    private sanitizer: DomSanitizer,
    public utilsService: UtilsService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.site.currentValue &&
        !!changes.site.previousValue &&
        changes.site.currentValue.site.uuid != changes.site.previousValue.site.uuid) {
      this.logoPresent = true;
      this.headerPresent = true;
    }
  }

  public get yelpInfo(): any {
    return this.utilsService.mapProviderInfo(this.site, 'YELP');
  }

  public getStreetView(site: SiteData): SafeResourceUrl {
    let queryParams;
    if (site.site.additionalProperties.streetView) {
      const streetView = JSON.parse(site.site.additionalProperties.streetView);
      queryParams = `&location=${streetView.latitude},${streetView.longitude}&heading=${streetView.heading}&pitch=${streetView.pitch}`;
    } else {
      queryParams = `&location=${site.address.latitude},${site.address.longitude}`;
    }
    let url = `https://www.google.com/maps/embed/v1/streetview?key=${environment.googleApiKey}${queryParams}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
