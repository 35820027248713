import { Component } from '@angular/core';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  isAuthenticated = this.authService.isAuthenticated.asObservable();
  constructor(private authService: AuthService) { }

}
