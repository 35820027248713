import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { environment } from '../../../environments/environment';
import { SiteData } from '../../shared/models/site';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit {
  @Input() site!: SiteData;
  @Input() height = '200px';
  // convert to proper field to keep iframe from reloading continuously
  placeUrl: SafeResourceUrl = null as any;

  constructor(
    private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.update();
  }

  // have to manually update because digest cycle will reload iframe continuously
  public update(): void {
    let address = encodeURIComponent(`${this.site.site.name},${this.site.address.address1} ${this.site.address.locality} ${this.site.address.region}`);
    let url = `https://www.google.com/maps/embed/v1/place?key=${environment.googleApiKey}&q=${address}&center=${this.site.address.latitude},${this.site.address.longitude}`;
    this.placeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
