import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-google-button',
  templateUrl: './google-button.component.html',
  styleUrls: ['./google-button.component.css']
})
export class GoogleButtonComponent {
  isAuthenticated = this.authService.isAuthenticated.asObservable();
  constructor(public authService: AuthService) { }
}
