import { AfterViewInit, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SiteListItem } from '../../models/site-list-item';

@Component({
  selector: 'app-site-list',
  templateUrl: './site-list.component.html',
  styleUrls: ['./site-list.component.css']
})
export class SiteListComponent implements AfterViewInit, OnChanges {
  @Input() siteSearchResults?: any;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<SiteListItem>;
  dataSource!: MatTableDataSource<SiteListItem>;
  resultsLength = 0;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['siteName', 'address1', 'locality', 'region', 'territory', 'visibility', 'merchantUuid', 'merchantName', 'accountId', 'corporation', 'offerCategory'];

  constructor(
    private router: Router) {
  }
  
  ngAfterViewInit(): void {
    this.dataSource = new MatTableDataSource<SiteListItem>(this.siteSearchResults.data);
    this.dataSource.sort = this.sort;
    this.table.dataSource = this.dataSource;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.resultsLength = changes.siteSearchResults.currentValue.total;
    if (this.dataSource) {
      this.dataSource.data = changes.siteSearchResults.currentValue.data;
    }
  }

  public navigateTo(siteUuid: string): void {
    this.router.navigate(['/site', siteUuid]);
  }
}
