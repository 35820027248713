import { Component, Input } from '@angular/core';

import { SiteData } from '../../shared/models/site';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent {
  @Input() site!: SiteData;

  constructor() { }

}
