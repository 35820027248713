<div class="grid-container" *ngIf="siteService.currentSite as site">
  <h1 class="mat-h1">{{ site.site.name }} Dashboard</h1>
  <mat-grid-list cols="12" rowHeight="96px">
    <mat-grid-tile *ngFor="let card of cards | async" [colspan]="card.cols" [rowspan]="card.rows">
      <mat-card class="dashboard-card">
        <mat-card-header>
          <mat-card-title>
            {{card.title}}
            <button *ngIf="!!card.edit && (authService.hasWriteAccess | async)" mat-icon-button class="more-button" [routerLink]="card.edit" aria-label="Edit button">
              <mat-icon>edit</mat-icon>
            </button>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="dashboard-card-content" [ngSwitch]="card.component">
          <app-details *ngSwitchCase="'details'" [site]="site"></app-details>
          <app-address *ngSwitchCase="'address'" [site]="site"></app-address>
          <app-account *ngSwitchCase="'account'" [site]="site"></app-account>
          <app-offer-details *ngSwitchCase="'offer-details'" [site]="site"></app-offer-details>
          <app-google *ngSwitchCase="'google'"></app-google>
          <app-yelp *ngSwitchCase="'yelp'" [site]="site"></app-yelp>
          <app-location *ngSwitchCase="'location'" [site]="site"></app-location>
          <app-images *ngSwitchCase="'images'" [site]="site"></app-images>
          <app-display-details *ngSwitchCase="'display-details'" [site]="site"></app-display-details>
          <div *ngSwitchDefault>Site Details Here</div>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
</div>
