<div class="container mat-background-default" *ngIf="opened">
    <p class="message mat-body-strong message-alert"><span [innerHTML]="message"></span></p>
    <div class="action-row">
        <button *ngFor="let action of actions; let idx=index;"
                type="button" mat-button color="accent"
                (click)="actionClicked(idx)">
            {{action}}
        </button>
    </div>
</div>
