import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import { MaterialModule } from './material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GoogleButtonComponent } from './shared/components/google-button/google-button.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavbarComponent } from './shared/components/navbar/navbar.component';
import { HomeComponent } from './home/home.component';
import { SiteComponent } from './site/site.component';
import { DetailsComponent } from './site/details/details.component';
import { EditDetailsComponent } from './site/edit-details/edit-details.component';
import { EditAddressComponent } from './site/edit-address/edit-address.component';
import { YelpComponent } from './site/yelp/yelp.component';
import { GoogleComponent } from './site/google/google.component';
import { AddressComponent } from './site/address/address.component';
import { AccountComponent } from './site/account/account.component';
import { DashboardComponent } from './site/dashboard/dashboard.component';
import { LocationComponent } from './site/location/location.component';
import { ImagesComponent } from './site/images/images.component';
import { SearchComponent } from './search/search.component';
import { SiteListComponent } from './shared/components/site-list/site-list.component';
import { DisplayDetailsComponent } from './site/display-details/display-details.component';
import { OfferDetailsComponent } from './site/offer-details/offer-details.component';
import { BannerOutletComponent } from './shared/components/banner/banner.component';
import { EditOfferDetailsComponent } from './site/edit-offer-details/edit-offer-details.component';

@NgModule({
  declarations: [
    AppComponent,
    GoogleButtonComponent,
    NavbarComponent,
    HomeComponent,
    SiteComponent,
    DetailsComponent,
    EditDetailsComponent,
    EditAddressComponent,
    YelpComponent,
    GoogleComponent,
    AddressComponent,
    AccountComponent,
    DashboardComponent,
    LocationComponent,
    ImagesComponent,
    SearchComponent,
    SiteListComponent,
    DisplayDetailsComponent,
    OfferDetailsComponent,
    BannerOutletComponent,
    EditOfferDetailsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AmplifyUIAngularModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})

export class AppModule { }
