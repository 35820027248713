import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { BannerService } from '../../shared/components/banner/banner.service';
import { SiteData } from '../../shared/models/site';
import { SiteAddress } from '../../shared/models/site-address';
import { SiteService } from '../../shared/services/site.service';
import { LocationComponent } from '../location/location.component';
import { Router } from '@angular/router';


@Component({
  selector: 'app-edit-address',
  templateUrl: './edit-address.component.html',
  styleUrls: ['./edit-address.component.css']
})
export class EditAddressComponent implements OnInit {
  @ViewChild(LocationComponent) location!: LocationComponent;
  site!: SiteData;
  addressForm = this.fb.group({
    address: [null, Validators.required],
    address2: null,
    city: [null, Validators.required],
    state: [null, Validators.required],
    postalCode: [null, Validators.compose([
      Validators.required, Validators.minLength(5), Validators.maxLength(5)])
    ],
    latitude: null,
    longitude: null,
    manuallyGeocode: null,
  });

  hasUnitNumber = false;

  states = [
    {name: 'Alabama', abbreviation: 'AL'},
    {name: 'Alaska', abbreviation: 'AK'},
    {name: 'American Samoa', abbreviation: 'AS'},
    {name: 'Arizona', abbreviation: 'AZ'},
    {name: 'Arkansas', abbreviation: 'AR'},
    {name: 'California', abbreviation: 'CA'},
    {name: 'Colorado', abbreviation: 'CO'},
    {name: 'Connecticut', abbreviation: 'CT'},
    {name: 'Delaware', abbreviation: 'DE'},
    {name: 'District Of Columbia', abbreviation: 'DC'},
    {name: 'Federated States Of Micronesia', abbreviation: 'FM'},
    {name: 'Florida', abbreviation: 'FL'},
    {name: 'Georgia', abbreviation: 'GA'},
    {name: 'Guam', abbreviation: 'GU'},
    {name: 'Hawaii', abbreviation: 'HI'},
    {name: 'Idaho', abbreviation: 'ID'},
    {name: 'Illinois', abbreviation: 'IL'},
    {name: 'Indiana', abbreviation: 'IN'},
    {name: 'Iowa', abbreviation: 'IA'},
    {name: 'Kansas', abbreviation: 'KS'},
    {name: 'Kentucky', abbreviation: 'KY'},
    {name: 'Louisiana', abbreviation: 'LA'},
    {name: 'Maine', abbreviation: 'ME'},
    {name: 'Marshall Islands', abbreviation: 'MH'},
    {name: 'Maryland', abbreviation: 'MD'},
    {name: 'Massachusetts', abbreviation: 'MA'},
    {name: 'Michigan', abbreviation: 'MI'},
    {name: 'Minnesota', abbreviation: 'MN'},
    {name: 'Mississippi', abbreviation: 'MS'},
    {name: 'Missouri', abbreviation: 'MO'},
    {name: 'Montana', abbreviation: 'MT'},
    {name: 'Nebraska', abbreviation: 'NE'},
    {name: 'Nevada', abbreviation: 'NV'},
    {name: 'New Hampshire', abbreviation: 'NH'},
    {name: 'New Jersey', abbreviation: 'NJ'},
    {name: 'New Mexico', abbreviation: 'NM'},
    {name: 'New York', abbreviation: 'NY'},
    {name: 'North Carolina', abbreviation: 'NC'},
    {name: 'North Dakota', abbreviation: 'ND'},
    {name: 'Northern Mariana Islands', abbreviation: 'MP'},
    {name: 'Ohio', abbreviation: 'OH'},
    {name: 'Oklahoma', abbreviation: 'OK'},
    {name: 'Oregon', abbreviation: 'OR'},
    {name: 'Palau', abbreviation: 'PW'},
    {name: 'Pennsylvania', abbreviation: 'PA'},
    {name: 'Puerto Rico', abbreviation: 'PR'},
    {name: 'Rhode Island', abbreviation: 'RI'},
    {name: 'South Carolina', abbreviation: 'SC'},
    {name: 'South Dakota', abbreviation: 'SD'},
    {name: 'Tennessee', abbreviation: 'TN'},
    {name: 'Texas', abbreviation: 'TX'},
    {name: 'Utah', abbreviation: 'UT'},
    {name: 'Vermont', abbreviation: 'VT'},
    {name: 'Virgin Islands', abbreviation: 'VI'},
    {name: 'Virginia', abbreviation: 'VA'},
    {name: 'Washington', abbreviation: 'WA'},
    {name: 'West Virginia', abbreviation: 'WV'},
    {name: 'Wisconsin', abbreviation: 'WI'},
    {name: 'Wyoming', abbreviation: 'WY'}
  ];

  constructor(
    private fb: FormBuilder,
    private siteService: SiteService,
    private bannerService: BannerService,
    private router: Router){}

  ngOnInit() {
        this.site = this.siteService.currentSite;
        this.addressForm.patchValue({
          siteUuid: this.site.site.uuid,
          address: this.site.address.address1,
          address2: this.site.address.address2,
          city: this.site.address.locality,
          state: this.site.address.region,
          postalCode: this.site.address.postCode,
          latitude: this.site.address.latitude,
          longitude: this.site.address.longitude,
          manuallyGeocode: false,
        });
        this.addressForm.markAsPristine();  // so we can see changes
  }

  onSubmit(): void {
    if (this.addressForm.pristine) {
      console.log('onSubmit', 'no changes to address form, skipping');
      return;
    }
    const address: SiteAddress = {
      id: this.site.address.id,
      uuid: this.site.address.uuid,
      address1: this.addressForm.get('address')?.value,
      address2: this.addressForm.get('address2')?.value,
      locality: this.addressForm.get('city')?.value,
      region: this.addressForm.get('state')?.value,
      postCode: this.addressForm.get('postalCode')?.value,
      countryCode: this.site.address.countryCode,
    };
    if (this.addressForm.get('manuallyGeocode')?.value) {
      address.latitude = this.addressForm.get('latitude')?.value;
      address.longitude = this.addressForm.get('longitude')?.value;
    }
    this.siteService.updateSiteAddress(this.site.site.uuid, address, !this.addressForm.get('manuallyGeocode')?.value).subscribe(
      (response: SiteAddress) => {
        // update site to update location component
        this.site.address.address1 = response.address1;
        this.site.address.address2 = response.address2;
        this.site.address.locality = response.locality;
        this.site.address.region = response.region;
        this.site.address.postCode = response.postCode;
        this.site.address.latitude = response.latitude;
        this.site.address.longitude = response.longitude;
        if (!this.addressForm.get('manuallyGeocode')?.value) {
          // need to update form in case address change moves lat/long
          this.addressForm.get('latitude')?.setValue(response.latitude);
          this.addressForm.get('longitude')?.setValue(response.longitude);
        }
        this.addressForm.markAsPristine(); // detect further changes
        this.location.update();
        this.makeAddressValidationMessage(response.validation);
      },
      error => {
        console.error('onSubmit', error);
        this.bannerService.open('The server returned an error, see console for more information.', ['Close']);
      });
  }
  makeAddressValidationMessage(validation: any) {
    if (!validation || validation.place_id) {
      return;
    }
    let message = 'The address is not quite valid';
    if (validation == 'PLACE_NOT_FOUND') {
      message = 'Could not find exact match on Google search.';

    }
    if (validation.DUPLICATE && validation.DUPLICATE != this.site.site.uuid) {
      message = `Site with this address already exist <a href="${this.router.createUrlTree(['/site', validation.DUPLICATE])}">${validation.DUPLICATE}</a>`;
    }
    if (validation.MISSING_PARTS) {
      message = `This address is missing ${validation.MISSING_PARTS}`
    }
    this.bannerService.open(message, ['Close']);
  }
}
