import { Component } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { SiteService } from 'src/app/shared/services/site.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {
  /** Based on the screen size, switch from standard to one column per row */
  cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      // use matches in field assignments
      return [
        { title: 'Address', cols: 5, rows: 3, edit: 'edit-address', component: 'address' },
        { title: 'Location', cols: 7, rows: 3, component: 'location' },
        { title: 'Display Details', cols: 12, rows: 10, component: 'display-details' },
        { title: 'Account', cols: 5, rows: 3, component: 'account' },
        { title: 'Offer Details', cols: 4, rows: 8, component: 'offer-details', edit: 'edit-offer-details' },
        { title: 'Yelp', cols: 8, rows: 8, xedit: 'edit-yelp', component: 'yelp' },
        { title: 'Data Details', cols: 4, rows: 8, edit: 'edit-details', component: 'details' },
        { title: 'Google', cols: 4, rows: 2, xedit: 'edit-google', component: 'google' },
        { title: 'Logo / Header', cols: 8, rows: 4, component: 'images' },
      ];
    })
  );

  constructor(
    private breakpointObserver: BreakpointObserver,
    public siteService: SiteService,
    public authService: AuthService) {}
}
