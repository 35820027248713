import { Component, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, ValidationErrors } from '@angular/forms';
import { map, switchMap } from 'rxjs/operators';

import { BannerService } from '../../shared/components/banner/banner.service';
import { SiteService } from '../../shared/services/site.service';
import { SiteData } from '../../shared/models/site';
import { Observable, of, timer } from 'rxjs';
import { ErrorStateMatcher } from '@angular/material/core';
import  {UtilsEditOfferDetails} from "./utils.edit-offer-details";

// only the form is set as valid or invalid.
// this provides visual feedback at control level
class OfferDetailsErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    // validation is at the top-level form, so it has the errors if any
    if (!!control && !!form && !!form!.errors) {
      // finding control name is PITA
      // easier to just use control.parent instead of top-level form
      const name = (Object
        .entries((control.parent as FormGroup)?.controls)
        .find(([key, value]) => value === control) as any[])[0];
      if (Object.keys(form!.errors).includes(name)) {
        return true;
      }
    }
    return false;
  }
}

@Component({
  selector: 'app-edit-offer-details',
  templateUrl: './edit-offer-details.component.html',
  styleUrls: ['./edit-offer-details.component.css'],
  providers: [
    { provide: ErrorStateMatcher, useClass: OfferDetailsErrorStateMatcher }
  ]
})
export class EditOfferDetailsComponent implements OnInit {
  site!: SiteData;
  // FYI form control names should match API names to make life easier
  defaultOfferSettings = this.fb.group({
    type: null,  // hidden
    distributionMean: null,
    distributionStdDev: null,
    roi: null,
    // gas
    minimumOffer: null,
    maximumOffer: null,
    // restaurant / grocery
    minimumThreshold: null,
    maximumCashback: null,
    marginPercent: null,
    minimumPercentOfMargin: null,
    maximumPercentOfMargin: null,
    feePercent: null,
  });
  marginSettings = this.fb.group({
    outsideGasFee: null,
    incrementalFee: null,
  });
  offerLimitSettings = this.fb.group({
    enabled: null,
    limit: null,
    period: null,
  });
  editForm = this.fb.group({
    defaultOfferSettings: this.defaultOfferSettings,
    marginSettings: this.marginSettings,
    offerLimitSettings: this.offerLimitSettings,
  });
  OFFER_LIMIT_PERIODS = ['DAILY', 'WEEKLY', 'MONTHLY'];

  constructor(
      private fb: FormBuilder,
      private siteService: SiteService,
      private bannerService: BannerService,
      private utilsEditOfferDetails: UtilsEditOfferDetails) {}

  ngOnInit(): void {
      this.site = this.siteService.currentSite;
      const defaultOfferSettings = this.site.site.settings.defaultOfferSettings.filter((e: { type: string; }) => e.type != 'default_offer_inside')[0];
      const marginSettings = this.site.site.settings.marginSettings;
      const offerLimitSettings = this.site.site.settings.offerLimitSettings;
      this.defaultOfferSettings.patchValue({
        type: defaultOfferSettings.type,
        distributionMean: defaultOfferSettings.distributionMean?.percent,
        distributionStdDev: defaultOfferSettings.distributionStdDev?.percent,
        roi: defaultOfferSettings.roi?.percent,
        minimumThreshold: defaultOfferSettings.minimumThreshold?.amount,
        maximumCashback: defaultOfferSettings.maximumCashback?.amount,
        marginPercent: defaultOfferSettings.marginPercent,
        minimumPercentOfMargin: defaultOfferSettings.minimumPercentOfMargin,
        maximumPercentOfMargin: defaultOfferSettings.maximumPercentOfMargin,
        feePercent: defaultOfferSettings.feePercent,
      });
      this.marginSettings.patchValue({
        outsideGasFee: marginSettings.outsideGasFee?.amount,
        incrementalFee: marginSettings.incrementalFee?.percent,
      });
      this.offerLimitSettings.patchValue(offerLimitSettings);
      // I never have good luck assigning async validator in FormBuilder
      this.editForm.asyncValidator = this.validateForm(this.site.site.uuid, this.siteService);
  }

  isGasSite(): boolean {
    return this.site!.site.offerCategory === 'GAS';
  }

  onSubmit(): void {
    this.siteService.updateSiteSettings(this.site!.site.uuid, this.editForm.value)
      .subscribe(
        () => {
          const validationResult = this.utilsEditOfferDetails.validateOfferCategoryDefaultValues(this);
          if (validationResult) {
            const message = validationResult.join("<br>");
            this.bannerService.open(message, ['Close']);
          }
          // update values to display on dashboard (avoids refresh - remove when refresh preferred)
          const defaultOfferSettings = this.site.site.settings.defaultOfferSettings.filter((e: { type: string; }) => e.type != 'default_offer_inside')[0];
          const marginSettings = this.site.site.settings.marginSettings;
          defaultOfferSettings.distributionMean.percent = this.editForm.value.defaultOfferSettings.distributionMean;
          defaultOfferSettings.distributionStdDev.percent = this.editForm.value.defaultOfferSettings.distributionStdDev;
          defaultOfferSettings.roi.percent = this.editForm.value.defaultOfferSettings.roi;
          if (defaultOfferSettings.minimumThreshold) {
            defaultOfferSettings.minimumThreshold.amount = this.editForm.value.defaultOfferSettings.minimumThreshold;
          }
          if (defaultOfferSettings.maximumCashback) {
            defaultOfferSettings.maximumCashback.amount = this.editForm.value.defaultOfferSettings.maximumCashback;
          }
          defaultOfferSettings.marginPercent = this.editForm.value.defaultOfferSettings.marginPercent;
          defaultOfferSettings.minimumPercentOfMargin = this.editForm.value.defaultOfferSettings.minimumPercentOfMargin;
          defaultOfferSettings.maximumPercentOfMargin = this.editForm.value.defaultOfferSettings.maximumPercentOfMargin;
          defaultOfferSettings.feePercent = this.editForm.value.defaultOfferSettings.feePercent;
          marginSettings.outsideGasFee.amount = this.editForm.value.marginSettings.outsideGasFee;
          marginSettings.incrementalFee.percent = this.editForm.value.marginSettings.incrementalFee;
          this.site.site.settings.offerLimitSettings = this.editForm.value.offerLimitSettings;
        },
        err => {
          console.error('update settings', err);
          this.bannerService.open('There was an error updating settings, check the console.', ['Close']);
        }
      );
  }

  validateForm(siteUuid: string, siteService: SiteService): AsyncValidatorFn {
    return (form: AbstractControl): Observable<ValidationErrors | null> => {
      // timer because Angular calls validate too many times
      return timer(200).pipe(
        switchMap(() => {
          // API is only validating offer settings for now
          const defaultOfferSettingsForm = form.get('defaultOfferSettings')! as FormGroup;
          if (!defaultOfferSettingsForm.value) {
            // reactive forms like to validate early and often - make sure there is something to validate
            return of(null);
          }
          return siteService.validateSiteSettings(siteUuid, defaultOfferSettingsForm.value)
          .pipe(
            map(results => {
              if (results && Object.keys(results).length > 0) {
                const errors = Object.entries(results)
                  .map(([key, value]) => ({[key]: (value as string[]).join(',')}))
                  .reduce((o: any, e: any) => ({...o, e}));
                  return errors;
              }
              return null;
            })
          );
        })
      );
    };
  }

  getErrorReason(name: string): string {
    const error = !!this.editForm.errors ? this.editForm.errors[name] : null;
    switch (error) {
      case 'out_of_range':
        return 'out of range';
      case 'too_low':
        return 'too low';
    }
    return 'unknown';
  }
}
