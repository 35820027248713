import { Component, Input } from '@angular/core';
import { SiteData } from 'src/app/shared/models/site';

@Component({
  selector: 'app-offer-details',
  templateUrl: './offer-details.component.html',
  styleUrls: ['./offer-details.component.css']
})
export class OfferDetailsComponent {
  @Input() site!: SiteData;

  constructor() { }

  private _defaultOffer! : any;
  public get defaultOffer() : any {
    if (!this._defaultOffer) {
      this._defaultOffer = this.site.site.settings.defaultOfferSettings.filter((e: { type: string; }) => e.type != 'default_offer_inside')[0];
    }
    return this._defaultOffer;
  }

  public get marginSettings() : any {
    return this.site.site.settings.marginSettings;
  }

  public get offerLimitSettings() : any {
    return this.site.site.settings.offerLimitSettings;
  }

}
