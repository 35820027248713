<div *ngIf="!!site">
    <mat-list role="list">
        <mat-list-item role="listitem">
            <mat-icon mat-list-icon>store</mat-icon>
            <div mat-line>{{ site.address.address1 }}</div>
            <div mat-line>{{ site.address.locality }} {{ site.address.region }} {{ site.address.postCode }}</div>
        </mat-list-item>
        <mat-list-item role="listitem">
            <mat-icon mat-list-icon>place</mat-icon>
            <div mat-line>Lat/Lon</div>
            <div mat-line>{{ site.address.latitude }} / {{ site.address.longitude }}</div>
        </mat-list-item>
    </mat-list>
</div>