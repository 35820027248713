import { Component, Input } from '@angular/core';

import { SiteData } from '../../shared/models/site';
import { UtilsService } from '../../shared/services/utils.service';

@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.css']
})
export class ImagesComponent {
  @Input() site!: SiteData;

  constructor(public utilsService: UtilsService) { }

}
