import { Component, Input } from '@angular/core';

import { SiteData } from 'src/app/shared/models/site';
import { UtilsService } from '../../shared/services/utils.service';

@Component({
  selector: 'app-yelp',
  templateUrl: './yelp.component.html',
  styleUrls: ['./yelp.component.css']
})
export class YelpComponent {
  @Input() site!: SiteData;
  DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  constructor(public utilsService: UtilsService) { }

  public get info(): any {
    return this.utilsService.mapProviderInfo(this.site, 'YELP');
  }

}
